import { refreshAccessToken } from 'helpers/refreshToken';
import { NextApiRequest, NextApiResponse } from 'next';
import NextAuth, { User } from 'next-auth';
import { default as KeycloakProvider } from 'next-auth/providers/keycloak';

export const signoutPage = '/signout';
export const errorPage = '/error';

export const signInMethodCookieName = 'x-sign-in-method';
export const b64DecodeUnicode = (str: string): string => {
	// Decode to unicode so æøå is converted correct
	// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
	return decodeURIComponent(
		atob(str)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(''),
	);
};

export default async function auth(req: NextApiRequest, res: NextApiResponse) {
	const signInMethod = req.cookies[signInMethodCookieName];
	let keyCloakIssuer: string;
	if (signInMethod === 'mitid') {
		keyCloakIssuer = process.env.KEYCLOAK_ISSUER_BASE + process.env.REALM_MITID;
	}
	if (signInMethod === 'azure') {
		keyCloakIssuer = process.env.KEYCLOAK_ISSUER_BASE + process.env.REALM_AZURE;
	}

	const providers = [
		KeycloakProvider({
			clientId: process.env.KEYCLOAK_CLIENT_ID,
			clientSecret: null,
			issuer: keyCloakIssuer,
			checks: ['pkce', 'state'],
			client: {
				// Tells next auth to ignore the client secret
				token_endpoint_auth_method: 'none',
			},
			authorization: {
				url: keyCloakIssuer,
				params: { grant_type: 'authorization_code', scope: 'openid bop' },
			},
			wellKnown: `${keyCloakIssuer}/.well-known/openid-configuration`,
			token: {
				url: `${keyCloakIssuer}/protocol/openid-connect/token`,
				params: {
					grant_type: 'authorization_code',
					response_type: 'code',
					redirect_uri: process.env.NEXTAUTH_URL,
				},
			},
		}),
	];

	return await NextAuth(req, res, {
		session: {
			strategy: 'jwt',
			maxAge: signInMethod === 'azure' ? 60 * 60 * 10 : 15, // next-auth.session-token cookie expirary = 10 hours in the future if azure else 15 seconds in the future
		},
		providers: providers,
		debug: false,
		pages: {
			error: errorPage,
			signOut: signoutPage,
			signIn: '/',
		},
		callbacks: {
			// This callback is executed each time a JSON Web Token is created (once during signin) or updated
			async jwt({ token, account, trigger }) {
				// Persist the OAuth access_token to the token right after signin
				if (trigger === 'update') {
					return refreshAccessToken(token, keyCloakIssuer);
				}
				if (account) {
					return {
						accessToken: account.access_token,
						accessTokenExpires: account.expires_at,
						refreshToken: account.refresh_token,
					};
				}
				token.signInMethod = req.cookies[signInMethodCookieName];
				const currentEpochSeconds = Math.floor(Date.now() / 1000);
				if (currentEpochSeconds < token.accessTokenExpires) return token;
				return refreshAccessToken(token, keyCloakIssuer);
			},
			async session({ session, token }) {
				// Send properties to the client, like an access_token from a provider.
				session.accessToken = token.accessToken;
				const base64User = token.accessToken.split('.')[1];
				const userLoginPayload: User = JSON.parse(b64DecodeUnicode(base64User));
				session.user = userLoginPayload;
				session.error = token.error;
				session.refreshToken = token.refreshToken;
				session.expires = String(new Date(token.accessTokenExpires * 1000 + 1000 * 60 * 10)); // 3 + 10 = 13 minutes
				return session;
			},
			async redirect({ url, baseUrl }) {
				// Allows relative callback URLs
				if (url.startsWith('/')) return `${baseUrl}${url}`;
				// Allows callback URLs of any origin
				return url;
			},
		},
	});
}
