export type ExplainerKey = Content.Theme | 'AsPDF' | 'GoTo' | 'LegalGuidanceForMoreInfo' | 'TheLegalGuidance';
import { Replacement } from '../dictionaryRepository';

interface DictionaryFunctions {
	get(key: ExplainerKey): string;
}

export const ExplainerItems = (getValue: (key: string, replacements?: Replacement[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: ExplainerKey): string => {
			switch (key) {
				case 'ADST':
					return getValue('Explainer.ADST', null, 'Administrations- og Servicestyrelsen');
				case 'GAELDST':
					return getValue('Explainer.GAELDST', null, 'Gældsstyrelsen');
				case 'ITTI':
					return getValue('Explainer.ITTI', null, 'It-tilsynet');
				case 'MOTORST':
					return getValue('Explainer.MOTORST', null, 'Motorstyrelsen');
				case 'SANST':
					return getValue('Explainer.SANST', null, 'Skatteankestyrelsen');
				case 'SKAT':
					return getValue('Explainer.SKAT', null, 'Skat');
				case 'SKM':
					return getValue('Explainer.SKM', null, 'Skatteministeriet');
				case 'SKTFV':
					return getValue('Explainer.SKTFV', null, 'Skattefovaltningen');
				case 'SKTST':
					return getValue('Explainer.SKTST', null, 'Skattestyrelsen');
				case 'SPIL':
					return getValue('Explainer.SPIL', null, 'Spillemyndigheden');
				case 'TOLDST':
					return getValue('Explainer.TOLDST', null, 'Toldstyrelsen');
				case 'UFST':
					return getValue('Explainer.UFST', null, 'Udviklings- og Forenklingsstyrelsen');
				case 'VURDST':
					return getValue('Explainer.VURDST', null, 'Vurderingsstyrelsen');
				case 'WEBGUIDE':
					return getValue('Explainer.WEBGUIDE', null, 'Udviklings- og Forenklingsstyrelsen');
				case 'LegalGuidanceForMoreInfo':
					return getValue('Explainer.LegalGuidanceForMoreInfo', null, 'For uddybende og fyldestgørende juridisk information, se');
				case 'TheLegalGuidance':
					return getValue('Explainer.TheLegalGuidance', null, 'Den juridiske vejledning');
				case 'AsPDF':
					return getValue('Explainer.AsPDF', null, 'Som PDF');
				case 'GoTo':
					return getValue('Explainer.GoTo', null, 'Gå til');
			}
		},
	};
};
